import React, { useState } from 'react';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';

const About = () => {
  let images = require.context('../../assets/images', true);

  const video = (
    <iframe
      width="420"
      height="260"
      src="https://www.youtube.com/embed/Vkkx5N6cZEA"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );

  const [leader, setLeader] = useState(null);

  const onSelectLeader = (selectedLeader) => {
    setLeader(
      <div className="flex flex-col p-10 bg-gray-100 shadow-xl sm:flex-row">
        <AiFillCloseCircle
          className="z-10 self-end -mb-4 text-3xl cursor-pointer sm:hidden hover:text-yellow text-blue"
          onClick={() => setLeader(null)}
        />
        <div
          className="w-full mb-6 bg-center bg-cover sm:mr-10 sm:mb-0 h-60"
          style={{ backgroundImage: `url(${selectedLeader.imagePath})` }}
        />
        <div>
          <div className="flex justify-between w-full">
            <p className="text-base font-semibold text-blue md:pb-0">{selectedLeader.name}</p>
            <AiFillCloseCircle
              className="hidden mx-2 mt-1 text-xl cursor-pointer sm:block hover:text-yellow text-blue"
              onClick={() => setLeader(null)}
            />
          </div>
          <p className="pb-10 text-sm sm:pb-20 text-blue md:pb-7">{selectedLeader.title}</p>
          <p>{selectedLeader.description}</p>
        </div>
      </div>,
    );
  };

  const leaders = [
    {
      id: 1,
      name: 'Jonathan Stark, Ed.D.',
      title: 'Executive Director  •  NMDSI',
      description: `Jonathan Stark has served as executive director of the NMDSI since May 2022. Since then, he has been tasked with building upon the foundation of the landmark collaboration between Northwestern Mutual, Marquette, and UWM by expanding data science research outcomes, talent development, and community engagement. Prior to joining NMDSI, Jonathan worked for nearly two decades in higher education, specializing in student services and academic affairs. Most recently, he served as the inaugural chief administrative and operations officer for Columbia University’s Data Science Institute and was instrumental in supporting the development and launch of Columbia’s data science master’s program. Jonathan holds a B.A. in psychology from Western Connecticut State University, an M.A. in applied organizational psychology from Fairfield University, an M.A. in higher education from Teachers College of Columbia University, and an Ed.D. in higher education management from the University of Pennsylvania.`,
      imagePath: images('./Jonathan_Stark.jpg').default,
    },
    {
      id: 2,
      name: 'Dr. Purush Papatla',
      title: 'Co-Director  •  UW-Milwaukee',
      description: `Purush Papatla is the Professor of Marketing at UW-Milwaukee’s Lubar School of Business. He is an expert in consumer marketing, specializing in Big Data and social media. Purush incorporates his mechanical engineering background and deep interest in psychology and anthropology into his data science work. He holds a bachelor’s degree in mechanical engineering from Osmania University in India, a master’s in industrial and systems engineering from the University of Illinois-Chicago, and a doctorate in marketing from the Kellogg School of Management at Northwestern University. As a co-director of the NMDSI, Purush is working to further expand his own teaching and research in data science as well as that of his colleagues and all colleges across the UWM campus. His goal is to graduate more students skilled in data science while having a significant and positive impact on the Milwaukee community.`,
      imagePath: images('./Purush_Papatla.jpg').default,
    },
    {
      id: 3,
      name: 'Scott Rex',
      title: 'Co-Director  •  Marquette University',
      description: `Scott Rex is the Director of the Business Analytics and Graduate Analytics programs at Marquette’s College of Business Administration. He teaches the undergraduate Business Analytics capstone course and also courses in marketing analytics, customer relationship management and marketing research. He holds an MBA from Vanderbilt University and a B.S. from Northwestern University. Scott has mentored numerous startup companies in the Milwaukee area and several startup accelerators. He brings more than 25 years of experience in marketing and consulting in the tech industry. As a co-director of the NMDSI, Scott is working to leverage his relationships in tech, corporations, and the Milwaukee community to help grow the NMDSI’s programs, scope and impact.`,
      imagePath: images('./Scott_Rex_2.jpg').default,
    },
    {
      id: 4,
      name: 'Lauren Domnick',
      title: 'Co-Director  •  Northwestern Mutual',
      description:
        'Lauren Domnick serves as vice president of Data Science Innovation in Northwestern Mutual’s Data Science and Analytics (DSA) organization. In this role, she leads the teams of data scientists, analysts and engineers who create AI/ML models and deliver analytical insights that drive operational efficiency, cost savings, and provide a seamless customer experience.  At Northwestern Mutual, Lauren has held leadership responsibility for accelerated underwriting, claims, servicing, managed investments, planning experience and illustrations. Lauren earned her B.A. in mathematics from University of Wisconsin-Whitewater before completing both an M.S. in biostatics from the Medical College of Wisconsin and an M.S. in statistics from the University of California Davis.',
      imagePath: images('./Lauren_Domnick.png').default,
    },
  ].map((leadersPerson, i) => (
    <div
      key={i}
      className="p-3 mb-6 rounded-lg shadow-md cursor-pointer hover:shadow-xl"
      onClick={() => onSelectLeader(leadersPerson)}
    >
      <img className="object-cover w-64 h-64 mb-6 rounded-sm" src={leadersPerson.imagePath} />
      <div>
        <p className="text-base font-semibold hover:text-yellow text-blue md:pb-0">{leadersPerson.name}</p>
        <p className="text-sm text-blue ">{leadersPerson.title}</p>
        <span className="text-sm text-gray-600 hover:text-blue">View Bio</span>
      </div>
    </div>
  ));

  const getLeader = () =>
    leader ? leader : <div className="flex flex-col items-center w-full sm:flex-row justify-evenly ">{leaders}</div>;

  const facultyPdfLink =
    'https://innovation.northwesternmutual.com/uploads/Affiliated_Faculty_Directory_d395a3a32f.pdf';

  const content = [
    {
      title: 'Our Story',
      body: [
        `In June 2018, Northwestern Mutual, Marquette University and the University of Wisconsin–Milwaukee
        announced the creation of the Northwestern Mutual Data Science Institute, a groundbreaking
        partnership that contributes to the formation of a technology ecosystem and advances southeastern
        Wisconsin as a national hub for technology, research, business and talent development, while
        creating an organic pipeline of tech talent in the area.
        
        `,
        `Our vision is to be a world-class institute that transforms our world through the power of
        data science. We inspire and cultivate passion for data science, galvanizing the brightest minds
        in the Milwaukee region and beyond to solve some of the world’s most pressing problems and inspire the next
        generation of data scientists.
        `,
        `
        Our focus in three key areas – research, talent, and community – allows us to solve important
        business and community challenges by harnessing the power of data for research and scientific
        discovery, preparing the next generation of data science professionals to advance the region, and
        partnering with area non-profit organizations to address issues facing some of our most vulnerable
        populations.`,
      ],
      video: video,
    },
    {
      title: 'What We Do',
      body: [
        ` With nearly $75 million invested from the three partner organizations since our launch in 2018, the
        NMDSI supports an endowed professorship at each university,
        research projects/grants, student scholarships, new data science faculty, development of expanded curriculum, K-12 STEM
        learning opportunities and pre-college programming, including
        <span>
          <a
            href="https://careers.northwesternmutual.com/teams/internships/"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            internships and certificates
          </a>
        </span>
        .`,
        ` Everything we do is rooted in our five Center of Excellence Focus Areas:
          <ol style="padding-left:2rem;list-style:decimal;">
            <li style="margin-top: 10px;"> Artificial Intelligence (AI) </li>
            <li style="margin-top: 10px;"> AI and Data Bias & Ethics </li>
            <li style="margin-top: 10px;"> Behavioral Economics </li>
            <li style="margin-top: 10px;"> Financial Literacy </li>
            <li style="margin-top: 10px;"> Health & Wealth Inequities </li>
          </ol>
        `,
        `We aim to pair world-class education with hands-on experience that leads to job
        opportunities in the field of data science in the Milwaukee region. Through the planning and
        resource support the NMDSI provides to
        <span>
          <a
            href="https://www.marquette.edu/data-science/"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
          >
            Marquette University
          </a>
        </span>
        and the
        <span>
          <a href="https://uwm.edu/data-science/" target="_blank" className="text-blue" rel="noreferrer">
            University of Wisconsin-Milwaukee
          </a>
        </span>
        , we train ethically grounded data science professionals who are also knowledgeable in various
        business disciplines and can apply their data science skills to a variety of industries.
        `,
        `Visit these leading institutions to learn more about their degree and certificate programs
        in data science.`,
      ],
      image: { src: images('./DSI_carasoul2.png').default, alt: 'What We Do' },
    },
    {
      title: 'Meet the leadership team',
      module: getLeader,
    },
    {
      title: 'AFFILIATED FACULTY PROGRAM',
      body: [
        `The NMDSI Affiliated Faculty program provides resources that support data science faculty, data
        science research and education, and also facilitating the building of expert multidisciplinary
        teams from faculty that have overlapping or complementary skills and interests. By building a data
        science community based on collaboration and increasing the collective impact of data science
        research, the NMDSI Affiliated Faculty program brings us one step closer to achieving our goal of
        transforming our world through the power of data science. <br />
        <br />
        Interested in joining the NMDSI Affiliated Faculty program? Submit your application below.
      `,
        `<a
        href="https://www.surveymonkey.com/r/KYQ5YY7"
        target="__blank"
        className="flex"
      >
        <span className="text-blue">Apply Now</span>
        <AiOutlineRight className="mx-2 mt-1 text-sm text-blue" />
      </a>
      <br />
      <br />
      <a href=${facultyPdfLink} target="__blank" className="flex">
        <span className="text-blue">View Affiliated Faculty</span>
        <AiOutlineRight className="mx-2 mt-1 text-sm text-blue" />
      </a>`,
      ],
      image: { src: images('./DSI_about_partner.png').default, alt: 'DSI partner' },
    },
  ];

  return (
    <div className="w-full my-20" id="about">
      <div className="max-w-6xl mx-auto">
        <div className="space-y-12">
          {content.map((item, i) => (
            <div
              key={i}
              className={
                i % 2 === 0
                  ? `flex flex-col items-center  sm:flex-row`
                  : `flex flex-col items-center sm:flex-row-reverse`
              }
            >
              <div className="flex-1 px-10 ">
                <h1 className="text-4xl font-bold uppercase text-blue mb-7">{item.title}</h1>
                {item.body &&
                  item.body.map((text, j) => (
                    <>
                      <div key={j}>
                        <div dangerouslySetInnerHTML={{ __html: text }} className="pb-3 text-base text-brown"></div>
                        <br />
                      </div>
                    </>
                  ))}
                {item.module && (
                  <div className="flex flex-col items-center w-full mt-24 sm:flex-row sm:justify-evenly">
                    {item.module()}
                  </div>
                )}
              </div>
              {item.image ? (
                <div className="flex flex-col items-center justify-center flex-1 w-full px-5 md:px-10">
                  <img src={item.image.src} alt={item.image.alt} className="w-full" />{' '}
                </div>
              ) : (
                item.video
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
